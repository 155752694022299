(function($) {
    Drupal.behaviors.validatePhoneNumber = {
        attach: function attach(context, settings) {
            $(document).ready(function() {
                addPhoneValidation($("input.phone-validation[type=text]"), settings);
            });

            function addPhoneValidation($telInput, settings) {
                if (!$telInput) {
                    return;
                }
                $telInput.on("change keyup", function(event) {
                    // Don't reformat backspace/delete and shift/tab so correcting mistakes is easier
                    if (event.keyCode != 46 && event.keyCode != 8 && event.keyCode != 9 && event.keyCode != 16) {
                        var val_old = $(this).val();
                        var newString = new libphonenumber.AsYouType("US").input(val_old);
                        $(this).val("").val(newString);
                    }
                });
            }
        }
    }
}(jQuery));
