/* eslint
    prettier/prettier: "off",
    no-shadow: "off",
    no-use-before-define: "off",
    block-scoped-var: "off",
    strict: "off",
    eqeqeq: "off",
    object-shorthand: "off",
    func-names: "off",
    no-useless-escape: "off",
    valid-jsdoc: "off",
    no-extend-native: "off",
    one-var: "off",
    no-redeclare: "off",
    no-restricted-globals: "off",
    no-cond-assign: "off",
    radix: "off",
    camelcase: "off",
    no-unused-vars: "off",
    no-undef: "off"
*/
(function($) {

  Drupal.behaviors.FormJS = {
    attach: function (context, settings) {
    /*
     * Every time the form field is changed, sanitize its contents with the given
     * function to only allow input of a certain form.
     */

    'use strict';

    var inputEvents = "input";
    if (!("oninput" in document || "oninput" in $("<input>")[0])) {
      inputEvents += " keypress keyup";
    }

    var iframes = $('iframe');
    var params = window.location.search;
    $.each(iframes, function (index, value) {
      var src = $(value).attr('src');
      $(value).attr('src', src + params);
    });

    jQuery.fn.restrict = function(sanitizationFunc) {

      return this.each(function() {
        // the element(s) to be restricted
        var $this = $(this);

        $this.bind(inputEvents, function() {
          var val = $this.val();
          var sanitizedVal = sanitizationFunc(val);

          if (val !== sanitizedVal) {
            $this.val(sanitizedVal);
          }
        });
      });
    };

    /*
     * Every time the form field is changed, modify its contents by eliminating
     * matches for the given regular expression within the field.
     */
    jQuery.fn.regexRestrict = function(regex) {
      var sanitize = function(text) {
        return text.replace(regex, '');
      };
      $(this).restrict(sanitize);
    };
    /**
     * Format values as money
     *
     * http://stackoverflow.com/a/149099/1060438
     */
    Number.prototype.formatMoney = function(c, d, t) {
      var n = this, c = isNaN( c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt( n = Math.abs(+n || 0).toFixed(c)) + "", j = ( j = i.length) > 3 ? j % 3 : 0;
      return s + ( j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + ( c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };

    /* These functions require the jquery.formrestrict.js functions to work!
     *
     * These functions can be used to replace the functionality of the jQuery
     * AlphaNumeric plugin. The usage of this plugin is almost identical to that of
     * the AlphaNumeric plugin.
     */

    jQuery.fn.alphanumeric = function(r) {
      alphanumericHelper(this, r, true, true, false);
    };
    jQuery.fn.numeric = function(r) {
      alphanumericHelper(this, r, false, true, false);
    };
    jQuery.fn.alpha = function(r) {
      alphanumericHelper(this, r, true, false, false);
    };
    jQuery.fn.alphanumericSpaces = function(r) {
      alphanumericHelper(this, r, true, true, true);
    };
    jQuery.fn.numericSpaces = function(r) {
      alphanumericHelper(this, r, false, true, true);
    };
    jQuery.fn.alphaSpaces = function(r) {
      alphanumericHelper(this, r, true, false, true);
    };
    var alphanumericHelper = function(obj, restraints, alpha, numeric, spaces) {
      var regex = "";
      if (spaces)
        regex += " ";
      if (numeric)
        regex += "0-9";
      if (alpha) {
        if (restraints == undefined || !restraints.allcaps)
          regex += "a-z";
        if (restraints == undefined || !restraints.nocaps)
          regex += "A-Z";
      }
      if (restraints != undefined && restraints.allow != undefined)
        regex += RegExp.escape(restraints.allow);

      $(obj).regexRestrict(RegExp("[^" + regex + "]", "g"))
    };

    /*
     * Function created by Colin Snover in response to an article by Simon Willison
     * on Regular Expression escaping in JavaScript:
     * http://simonwillison.net/2006/Jan/20/escape/
     */
    RegExp.escape = function(text) {
      return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    };

    /* ******************************************************
     * Move description text into label tags so can be
     * read by screenreaders.
     ****************************************************** */
    function process_form_descriptions() {
      $('.form-item').each(function() {
        if ($(this).find(".description").length) {
          var desc_text = $(this).find(".description").text();
          // append description to label text with the
          // hidden class for screen readers
          $(this).find("label").append('<span class="help-text visuallyhidden">' + desc_text + '</span>');
        }
      });
      $('.description').each(function() {
        if ($(this).html().match(/(<p>)(\s*By Clicking.*your credit card will be processed.*)/i)) {
          // Add tabindex for accessibility for submit buttons with credit card processing
          var new_desc = $(this).html().replace(/(<p>)(\s*By Clicking.*your credit card will be processed.*)/i, '<p tabindex="-1">$2');
          // If this message appears after a submit button
          // add it before the submit for screen readers
          if ($(this).prev('div').html().match(/type\=[\'|\"]submit[\'|\"]/i)) {
            $(this).prev('div').prepend("<span class='visuallyhidden'>" + new_desc + "</span>");
          }
        }

      });
    }

    // Polyfill for the ES 6 endsWith function
    if (!String.prototype.endsWith) {
      String.prototype.endsWith = function(searchString, position) {
        var subjectString = this.toString();
        if ( typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
          position = subjectString.length;
        }
        position -= searchString.length;
        var lastIndex = subjectString.indexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
      };
    }

    // Touch Functions used for ios 7.0 phones to show number keyboard for input fields that take numeric values
    function touchStart(e) {
      e.target.type = "number";
    }

    // But we don't want the number input validation (it breaks the page on submit) so change it back upon text enter
    function touchType(e) {
      e.target.type = "text";
    }

    $(document).ready(function() {
      process_form_descriptions();

      // Add class so we know it's a confirmation page
      if ($('.webform-confirmation').length) {
        $('#container').addClass('confirmation');
      }

      // Add tabindex to privacy policy and quicksign labels
      // applicable for social actions, message actions and petitions
      $('#webform-component-privacy-policy p').attr('tabindex',0);
      $('.quicksign-label-container .quicksign-label').attr('tabindex',0);
      $('.quicksign-description p').attr('tabindex',0);

      // Customized processing message.
      $('.webform-user-wrapper').initialize(function(){
        $(this).html('<div class="processing-spinner"></div><p class="processing-text">Thank you! Your submission is being processed.</p>');
        var opts = {
          lines : 13, // The number of lines to draw
          length : 3, // The length of each line
          width : 2, // The line thickness
          radius : 3, // The radius of the inner circle
          corners : 0.5, // Corner roundness (0..1)
          rotate : 0, // The rotation offset
          direction : 1, // 1: clockwise, -1: counterclockwise
          color : '#000', // #rgb or #rrggbb or array of colors
          speed : 1, // Rounds per second
          trail : 25, // Afterglow percentage
          shadow : false, // Whether to render a shadow
          hwaccel : false, // Whether to use hardware acceleration
          className : 'spinner', // The CSS class to assign to the spinner
          zIndex : 2e9, // The z-index (defaults to 2000000000)
          top : '50%', // Top position relative to parent
          left : '50%' // Left position relative to parent
        };
        const Spinner = require('./vendor/spin.js');
        var spinner = new Spinner(opts).spin();
      });

      /*
        * This is based on ideas from a technique described by Alen Grakalic in
        * http://cssglobe.com/post/8802/custom-styling-of-the-select-elements
        */
      $.fn.customSelect = function(settings) {
          var config = {
              replacedClass : 'replaced', // Class name added to replaced selects
              customSelectClass : 'custom-select', // Class name of the (outer) inserted span element
              activeClass : 'active', // Class name assigned to the fake select when the real select is in hover/focus state
              wrapperElement : '<div class="custom-select-container" />' // Element that wraps the select to enable positioning
          };
          if (settings) {
              $.extend(config, settings);
          }
          this.each(function() {
            var select = $(this);
            select.addClass(config.replacedClass);
            select.wrap(config.wrapperElement);
            var update = function() {
              var val = $('option:selected', this).text();
              span.find('span span').text(val);
            };
            // Update the fake select when the real selectÃ¢â‚¬â„¢s value changes
            select.change(update);
            /* Gecko browsers don't trigger onchange until the select closes, so
              * changes made by using the arrow keys aren't reflected in the fake select.
              * See https://bugzilla.mozilla.org/show_bug.cgi?id=126379.
              * IE normally triggers onchange when you use the arrow keys to change the selected
              * option of a closed select menu. Unfortunately jQuery doesnÃ¢â‚¬â„¢t seem able to bind to this.
              * As a workaround the text is also updated when any key is pressed and then released
              * in all browsers, not just in Firefox.
              */
            select.keyup(update);
            /* Create and insert the spans that will be styled as the fake select
            * To prevent (modern) screen readers from announcing the fake select in addition to the real one,
            * aria-hidden is used to hide it.
            */
            // Three nested spans? The only way I could get text-overflow:ellipsis to work in IE7.
            var span = $('<span class="' + config.customSelectClass + '" aria-hidden="true"><span><span>' + $('option:selected', this).text() + '</span></span></span>');
            select.after(span);
          });
        };


        /*  ==================================================
        ========= American Civil Liberties Union =========

        ================== www.aclu.org ==================

        Description:
        Global Javascript, applied to all templates

        Author:
        Chuck Harmston, Threespot Media
        http://www.threespot.com

        Copyright:
        Subject to copyright described at aclu.org

        ================================================== */

        // Check to see which operating system we're using.
        if (navigator.appVersion.indexOf("Mac") != -1) {
          $('html').addClass('mac');
        } else {
          $('html').addClass('pc');
        }
        // Check to see if the browser is Safari and doublecheck that it is not Chrome.
        if (navigator.userAgent.indexOf('Chrome') > -1) {
          $('html').addClass('chrome');
        }
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
          $('html').addClass('safari');
        }
        if (navigator.userAgent.indexOf('Firefox') > -1) {
          $('html').addClass('firefox');
        }
        if (navigator.userAgent.indexOf('MSIE') > -1) {
          $('html').addClass('ie');
        }
        // Add text after the zip code field for screen reader accessibility.
        $('#webform-component-billing-information--zip').append('<span class="screenreader-only">Filling out zip code will auto-populate your city and state/province below.</span>');

        $('form.webform-client-form').each(function(i, v) {
          configUpdateCityAndStateByZip("#webform-component-billing-information--zip", "#webform-component-billing-information--city", "#webform-component-billing-information--state", "#webform-component-billing-information--country", v);
        });

        // Support legacy blockquotes by adding a <p> tag inside of blockquotes if there
        // is not already a <p> tag.
        $("#node-content .body blockquote").each(function(index) {
          if ($(this).find('p').length == 0) {
            $(this).wrapInner('<p>');
          }
        });

        $('form.webform-client-form').each(function(i, v) {
          configUpdateCityAndStateByZip("#webform-component-billing-information--zip", "#webform-component-billing-information--city", "#webform-component-billing-information--state", "#webform-component-billing-information--country", v);
        });
        function configUpdateCityAndStateByZip(zipId, cityId, stateId, countryId, v) {
          var zipObj = $(zipId + " input", v);
          var cityObj = $(cityId + " input", v);
          var stateObj = $(stateId + " select", v);
          var countryObj = $(countryId + " select", v);

          // Make sure only 1 form field matches each of the ID parameters supplied.
          if (zipObj.length == 1 && cityObj.length == 1 && stateObj.length == 1 && countryObj.length == 1) {
            zipObj.bind("blur", function() {
            // Only make the ajax request if we've got a valid 5-digit zip code, US is the selected country, and either the city or state field are empty.
            if ((/^\d{5}$/.test($(this).val())) && countryObj.val() == 840 && (cityObj.val().trim() == "" || stateObj.val().trim() == "")) {
              $.ajax({
                url : "/aclu_custom/autopop-city-state-by-zip",
                dataType : "json",
                type : "POST",
                data : "zip=" + $(this).val(),
                success : function(result, success) {
                    if (cityObj.val().trim() == "") {
                      cityObj.val(result.city);
                    }
                    if (stateObj.val().trim() == "") {
                      stateObj.val(result.state_id);

                      // Overwrite the HTML of the span used to display the select box value on responsive forms
                      var targetOption = stateObj.find("option[value=" + result.state_id + "]");
                      stateObj.next().filter(".custom-select").find("span > span").html(targetOption.html());
                    }
                  }
                });
              }
            });
          }
        }

        /* Create the share menu */
        $('ul.article_tools li.share, #block-aclu_blocks-video_share div.share').show();

        /* Share Menu button */
        $("ul.article_tools a.toggle").bind("click", function(event) {
          event.preventDefault();
          var $share = $(this).siblings('ul');
          if ($share.css('visibility') == 'hidden') {
            $share.css('visibility', 'visible');
          } else {
            $share.css('visibility', 'hidden');
          }
        });

        /* Hide submit button in affiliate search block */
        $('#find input.form-submit').hide();

        /* Append correct submit button text to description */
        var name = $("#edit-submit").attr("value");
        $("#submit-value").html(name);

        /* Auto-clear form fields */
        $("#edit-search-theme-form-1, #findmultimedia #edit-keyword, #browsecases #edit-keyword, #edit-search, #edit-search-1, #rep_resolverzip5, #rep_resolverzip4, #email, #footer_default form#signup #signup_email_footer").focus(function() {
          if (this.value == this.defaultValue) {
            $(this).attr("value", "");
          }
        }).blur(function() {
          if (!this.value.length) {
            $(this).attr("value", this.defaultValue);
          }
        });

        /* Add class on mouseover... */
        $(".meta-type a").bind("mouseover", function(event) {
          $(this).parent('.meta-type').parent().addClass('highlight');
        }).bind("mouseout", function(event) {
          $(this).parent('.meta-type').parent().removeClass('highlight');
        });
        /* Add class on mouseover for search page submit form... */
        $("#search #edit-submit, #edit-long-submit").bind("mouseover", function(event) {
          $(this).addClass('highlight');
        }).bind("mouseout", function(event) {
          $(this).removeClass('highlight');
        });
        // Adapted from http://furrybrains.com/2009/01/02/capturing-autofill-as-a-change-event/
        $.fn.listenForChange = function(options) {
          settings = $.extend({
            interval : 200 // in microseconds
          }, options);

          var jquery_object = this;
          var current_focus = null;

          jquery_object.filter(":input").add(":input", jquery_object).filter('[type="text"],[type="radio"],[type="checkbox"],[type="file"],textarea').focus(function() {
            current_focus = this;
          }).blur(function() {
            current_focus = null;
          });

          window.furryBrainsInterval = setInterval(function() {
            // allow
            jquery_object.filter(":input").add(":input", jquery_object).filter('[type="text"],[type="radio"],[type="checkbox"],[type="file"],textarea').each(function() {
              // set data cache on element to input value if not yet set
              if ($(this).data('change_listener') == undefined) {
                $(this).data('change_listener', $(this).val());
                return;
              }
              // return if the value matches the cache
              if ($(this).data('change_listener') == $(this).val()) {
                return;
              }
              // ignore if element is in focus (since change event will fire on blur)
              if (this == current_focus) {
                return;
              }
              // if we make it here, manually fire the change event and set the new value
              if ($(this).val()) {
                $(this).prev().hide();
              }
            });
          }, settings.interval);
          return this;
        };

        // Apply customselect dropdown classes and markup
        $(".default-form-wrapper #content form").each(function(index) {
          var form_id = $(this).attr('id');
          var formSelectorID = "#" + form_id;
          $(formSelectorID + " select").each(function() {
            if (!$(this).hasClass("custom")) {
              $(this).addClass("custom");
              $(this).prev().andSelf().wrapAll('<div class="select">');
              $(this).customSelect();
            }
          });
        });

        $(".default-form-wrapper #content form").each(function(index, formContext) {

            var form_id = $(this).attr('id');
            var formSelectorID = "#" + form_id + " ";

            $(formSelectorID + 'input[type="checkbox"]').each(function() {
              var parentElement = $(this).parent();
              var attr = parentElement.attr('for');
              // For some browsers, `attr` is undefined; for others,
              // `attr` is false.  Check for both.
              if ( typeof attr !== typeof undefined && attr !== false) {
                $(this).insertBefore(parentElement);
              }
            });

            // To fix radio button label text line break for super long lable text after radio input
            // place input inside label
            // $(formSelectorID + 'input[type="radio"]').each(function() {
            //   var curLabel = $(this).parent().find('label');
            //   var attr = curLabel.attr('for');

            // For some browsers, `attr` is undefined; for others,
            // `attr` is false.  Check for both.
            //   if ( typeof attr !== typeof undefined && attr !== false) {
            //      curLabel.wrapInner("<span class='button-label' rel='" + $(this).text() + "'><span>");
            //       curLabel.parent().prepend('<span class="psuedo-radio-label"></span>');
            // Breaks prepopulate wip fix
            //      curLabel.prepend($(this));
            //   }
            // });

            $(formSelectorID + '.webform-component-fieldset legend').each(function() {
              if (!$(this).hasClass("collapse-processed")) {
                if ($(this).html().toLowerCase().indexOf("(optional)") >= 0) {
                  var wrapOptional = $(this).html().replace(/\(optional\)/i, "<span class='legend-paren-text'>(optional)</span>");
                  $(this).html(wrapOptional);
                }
              }
            });
        });
    });

    //  Wrap social share icons in p tags if not already.
    $('.webform-confirmation .sb_social_default_style > .social-share-link', context).wrap('<p></p>');

    // Rearrange social share icons so email comes last
    $('.webform-confirmation .sb_social_button_email').parent().insertAfter($('.sb_social_button_twitter').parent());

    // JS to handle the multiple phone-related fields
    $('form.webform-client-form', context).once('detect-phone-scenario', function() {

      function isLegacyMobilePhoneOptionalScenario() {
        return (
          $('input[type=text][name^="submitted"][name$="[mobile_phone_number]"]', this).length === 1 &&
          $('input[type=hidden][name^="submitted"][name$="[field_phone_type]"]', this).length === 1 &&
          $('input[type=hidden][name^="submitted"][name$="[sms_opt_in]"]', this).length === 1 &&
          $('input[type=hidden][name^="submitted"][name$="[mobile_autodial_opt_in]"]', this).length === 1
        );
      }

      function isLegacyPhoneAndTypeRequiredScenario() {
        return (
          $('input[type=text][name^="submitted"][name$="[sbp_phone]"]', this).length === 1 &&
          $('select[name^="submitted"][name$="[field_phone_type]"]', this).length === 1 &&
          $('input[type=checkbox][name^="submitted"][name$="[sms_opt_in][1]"]', this).length === 1 &&
          $('input[type=hidden][name^="submitted"][name$="[mobile_autodial_opt_in]"]', this).length === 1
        );
      }

      function isPhoneOptionalTypeHiddenOptinImplicitScenario() {
        return (
        $('input:not(.required)[type=text][name^="submitted"][name$="[sbp_phone]"]', this).length === 1 &&
        $('input[type=hidden][name^="submitted"][name$="[field_phone_type]"]', this).length === 1 &&
        $('input[type=hidden][name^="submitted"][name$="[sms_opt_in]"]', this).length === 0 &&
        $('input[type=hidden][name^="submitted"][name$="[mobile_autodial_opt_in]"]', this).length === 0 &&
        $('input[type=hidden][name^="submitted"][name$="[phone_scenario]"]', this).val() === "PhoneOptionalTypeUnknownOptinImplicit"
        );
      }

      function isPhoneRequiredTypeHiddenOptinExplicitScenario() {
        return (
          $('input.required[type=text][name^="submitted"][name$="[sbp_phone]"]', this).length === 1 &&
          $('input[type=hidden][name^="submitted"][name$="[field_phone_type]"]', this).length === 1 &&
          $('input[type=checkbox][name^="submitted"][name$="[sms_opt_in][1]"]', this).length === 1 &&
          $('input[type=hidden][name^="submitted"][name$="[mobile_autodial_opt_in]"]', this).length === 1 &&
          $('input[type=hidden][name^="submitted"][name$="[phone_scenario]"]', this).val() === "PhoneRequiredTypeUnknownOptinExplicitScenario"
        );
      }

      function syncOptInFields() {
        if ($('input[type=checkbox][name^="submitted"][name$="[sms_opt_in][1]"]', this)[0].checked) {
          // If the user has opted in to SMS, also opt in to autodial
          $('input[type=hidden][name^="submitted"][name$="[mobile_autodial_opt_in]"]', this).val('1');
        }
        else {
          // If the user opted out of SMS, also opt out of autodial
          $('input[type=hidden][name^="submitted"][name$="[mobile_autodial_opt_in]"]', this).val('');
        }
      }

      if (isLegacyMobilePhoneOptionalScenario()) {
        // Scenario: Mobile phone optional (if given, SMS and autodial opt-ins implicit)
        $(this).submit(function () {
          if ($.trim($('input[type=text][name^="submitted"][name$="[mobile_phone_number]"]', this).val()).length === 0) {
            // The user has not given a mobile phone number, blank out the phone fields.
            $('input[type=text][name^="submitted"][name$="[mobile_phone_number]"]', this).val('');
            $('input[type=hidden][name^="submitted"][name$="[field_phone_type]"]', this).val('');
            $('input[type=hidden][name^="submitted"][name$="[sms_opt_in]"]', this).val('');
            $('input[type=hidden][name^="submitted"][name$="[mobile_autodial_opt_in]"]', this).val('');
          }
          else {
            // The user has given a mobile phone number, make sure type is set to 'Cell' and opt-ins set.
            $('input[type=hidden][name^="submitted"][name$="[field_phone_type]"]', this).val('Cell');
            $('input[type=hidden][name^="submitted"][name$="[sms_opt_in]"]', this).val('1');
            $('input[type=hidden][name^="submitted"][name$="[mobile_autodial_opt_in]"]', this).val('1');
          }
        });
      }
      else if (isLegacyPhoneAndTypeRequiredScenario()) {
        // Scenario: Phone and phone type required (SMS and autodial opt-ins explicit)
        $(this).submit(function () {
          if ($('select[name^="submitted"][name$="[field_phone_type]"]', this).val() === 'Home') {
            // If we've been given a home phone, don't opt in to SMS
            $('input[type=checkbox][name^="submitted"][name$="[sms_opt_in][1]"]', this)[0].checked = false;
          }

          // Keep the SMS and autodial opt-ins in sync.
          syncOptInFields();
        });
      }
      else if (isPhoneOptionalTypeHiddenOptinImplicitScenario()) {
        // Scenario: Phone optional, type unknown (if given, SMS and autodial opt-ins implicit)
        // Nothing to do for this scenario.
      }
      else if (isPhoneRequiredTypeHiddenOptinExplicitScenario()) {
        // Scenario: Phone required, phone type hidden/unknown (SMS and autodial opt-ins explicit)
        $(this).submit(function () {
          // Keep the SMS and autodial opt-ins in sync.
          syncOptInFields();
        });
      }
    });

    // Update the Salesforce Donor Preference to reflect whether or not the user is joining People Power
    $('form.webform-client-form', context).once('ppp-salesforce-donor-preference', function() {
      $(this).submit(function() {
        if (
            ($('input[type=hidden][name^="submitted"][name$="[sms_opt_in]"]', this).length && $('input[type=hidden][name^="submitted"][name$="[sms_opt_in]"]', this).val()) ||
            ($('input[type=checkbox][name^="submitted"][name$="[sms_opt_in][1]"]', this)[0] && $('input[type=checkbox][name^="submitted"][name$="[sms_opt_in][1]"]', this)[0].checked)
          )
        {
          $('input[name="submitted[salesforce_donor_preference]"]', this).val('PPP');
        }
        else {
          $('input[name="submitted[salesforce_donor_preference]"]', this).val('');
        }
      });
    });

    // Look for iframes containing pref center and apply a class to the body
    if ($('.preference-center-container', context).length > 0) {
      $('body').addClass('pref-center-page');

      let frameDomain = 'aclu360.my.salesforce-sites.com';
      if (window.location.hostname === 'aclu.hosted.jacksonriverdev.com') {
        frameDomain = 'aclu360--full2.sandbox.my.salesforce-sites.com';
      }
      var frameTarget = `https://${frameDomain}/ManageEmails${document.location.search}`;
      $('<iframe src="' + frameTarget + '" frameborder="0" scrolling="no" id="myFrame"></iframe>').appendTo('.preference-center-container');

      // Preference center style overrides
      $('#main-content>h1').hide();
    }

      // Process query string for forms to set default values.
      var queryStrings = Drupal.behaviors.FormJS.getQueryParams(document.location.search);

      // Autopopulate the sms rev ID field from the 'sms_rev_id' URL parameter
      var sms_rev_id_input = $('input[name$="[sms_rev_id]"]', context);
      if (queryStrings.hasOwnProperty('sms_rev_id') && sms_rev_id_input.length > 0) {
        sms_rev_id_input.once('sms-rev-id-set-from-url-parameter').val(queryStrings.sms_rev_id);
      }

      // Autopopulate the membership ID field from the 'membership_id' URL parameter
      var membership_id_input = $('input[name$="[membership_id]"]', context);
      if (queryStrings.hasOwnProperty('membership_id') && membership_id_input.length > 0) {
        membership_id_input.once('membership-id-set-from-url-parameter').val(queryStrings.membership_id);
      }

      // Autopopulate the salesforce ID field from the 'salesforce_id' URL parameter
      var salesforce_id_input = $('input[name$="[salesforce_id]"]', context);
      if (queryStrings.hasOwnProperty('salesforce_id') && salesforce_id_input.length > 0) {
        salesforce_id_input.once('salesforce-id-set-from-url-parameter').val(queryStrings.salesforce_id);
      }

      // Autopopulate the campaign member ID field from the 'campaign_member_id' URL parameter
      var campaign_member_id_input = $('input[name$="[campaign_member_id]"]', context);
      if (queryStrings.hasOwnProperty('campaign_member_id') && campaign_member_id_input.length > 0) {
        campaign_member_id_input.once('campaign-member-id-set-from-url-parameter').val(queryStrings.campaign_member_id);
      }
    }
  }

  // Finds DOM elements with a class of countdown-end-datetime and looks inside
  // them for an end time. Calculates, displays and updates the amount of time
  // between now and that end time.
  Drupal.behaviors.Countdown = {
    attach: function (context, settings) {
      'use strict';

      $('.countdown-end-datetime', context).each(function() {

        function updateCountdown(countdownEndTime, countdownTickerElement) {
          var now = new Date().getTime();
          var timeRemainingInCountdown = countdownEndTime - now;

          // If the countdown is finished, stop it
          if (timeRemainingInCountdown < 0) {
            clearInterval(interval);
            return;
          }

          // Time and label calculations
          var days = Math.floor(timeRemainingInCountdown / (1000 * 60 * 60 * 24));
          var dayLabel = days === 1 ? "day" : "days";

          var hours = Math.floor((timeRemainingInCountdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var hoursLabel = hours === 1 ? "hour" : "hours";

          var minutes = Math.floor((timeRemainingInCountdown % (1000 * 60 * 60)) / (1000 * 60));
          var minutesLabel = minutes === 1 ? "min" : "mins";

          var seconds = Math.floor((timeRemainingInCountdown % (1000 * 60)) / 1000);
          var secondsLabel = seconds === 1 ? "sec" : "secs";

          // Update the DOM
          var updatedCountDownTickerElementHTML =
            "<div class='countdown-col'><div class='countdown-num'>" + zeroPad(days, 2) + "</div><div class='countdown-unit'>" + dayLabel + "</div></div>" +
            "<div class='countdown-colon countdown-col'>:</div>" +
            "<div class='countdown-col'><div class='countdown-num'>" + zeroPad(hours, 2) + "</div><div class='countdown-unit'>" + hoursLabel + "</div></div>" +
            "<div class='countdown-colon countdown-col'>:</div>" +
            "<div class='countdown-col'><div class='countdown-num'>" + zeroPad(minutes, 2) + "</div><div class='countdown-unit'>" + minutesLabel + "</div></div>" +
            "<div class='countdown-colon countdown-col'>:</div>" +
            "<div class='countdown-col'><div class='countdown-num'>" + zeroPad(seconds, 2) + "</div><div class='countdown-unit'>" + secondsLabel + "</div></div>";

          if (timeRemainingInCountdown < (1000 * 60 * 60 * 12)) {
            // If < 12 hrs remaining, display the countdown in red
            updatedCountDownTickerElementHTML = '<span class="imminent-deadline">' + updatedCountDownTickerElementHTML + '</span>';
          }

          countdownTickerElement.html(updatedCountDownTickerElementHTML);
        }

        function zeroPad(num, size) {
          var s = "0" + num;
          return s.substr(s.length - size);
        }

        // Set the date we're counting down to
        var countdownEndTime;
        if ($(this).attr('data-end')) {
          countdownEndTime = $(this).attr('data-end').trim().replace(/-/g, "/");
          countdownEndTime = new Date(countdownEndTime).getTime();
        }

        // Set the date we will start displaying the countdown
        var countdownStartTime;
        if ($(this).attr('data-start')) {
          countdownStartTime = $(this).attr('data-start').trim().replace(/-/g, "/");
          countdownStartTime = new Date(countdownStartTime).getTime();
        }

        var now = new Date().getTime();
        if ((countdownEndTime && now < countdownEndTime) && (!countdownStartTime || now >= countdownStartTime)) {
          var countdownTickerElement = $('<div class="countdown-ticker" aria-live="off"></div>');
          $(this).replaceWith(countdownTickerElement);
          countdownTickerElement.after('<span class="screenreader-only">This is a countdown clock, counting down till the new year.</span>');
          var interval = setInterval(updateCountdown, 1000, countdownEndTime, countdownTickerElement);
          updateCountdown(countdownEndTime, countdownTickerElement);
        }
      });
    }
  };

  // returns an object with the query strings as key:value
  Drupal.behaviors.FormJS.getQueryParams = function(qs) {
    qs = qs.split("+").join(" ");
    var params = {};
    var tokens;
    var regex = /[?&]?([^=]+)=([^&]*)/g;
    while (tokens = regex.exec(qs)) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
  };

  // returns the value of a URL parameter if it exists. Otherwise, returns
  // false.
  Drupal.behaviors.FormJS.getQueryParamValue = function(parameter, queryString) {

    var parameters = Drupal.behaviors.FormJS.getQueryParams(queryString);
    if (parameters.hasOwnProperty(parameter)) {
      return parameters[parameter];
    }

    return false;
  };

  Drupal.behaviors.actOnCaptcha = {
    attach: function (context, settings) {
      'use strict';

      $('.captcha', context).once('general-captcha-changes', function() {

        // On certain node types we need to wrap some captcha elements
        // for styling purposes.
        if (
          ($('.node-type-donation-form').length && $('.multistep-donation').length === 0) || // 1-step donation forms
          $('.node-type-ticketed-event').length ||
          $('.node-type-p2p-donation-form').length
        ) {
          $('fieldset.captcha #edit-captcha-response, fieldset.captcha .error, fieldset.captcha .description', context).wrapAll('<div class="captcha-extra-info"></div>');
          $('.captcha-extra-info').parent().addClass('one-line-desktop');
        }
      });
    }
  };
})(jQuery);