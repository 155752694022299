window.addEventListener("DOMContentLoaded", function () {
  const accordionItems = document.querySelectorAll('.accordion-item');
  accordionItems.forEach(item => {
    const header = item.querySelector('.accordion-header');
    header.addEventListener('click', () => {
      const openItem = document.querySelector('.accordion-item.active');
      const openHeader = document.querySelector('.accordion-header.active');
      if (openItem && openItem !== item) {
        openHeader.setAttribute('aria-expanded', 'false');
        openHeader.classList.remove('active');
        openItem.classList.remove('active');
      }
      item.classList.toggle('active');
      header.classList.toggle('active');
      header.ariaExpanded = header.ariaExpanded !== 'true';
    });
    header.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        header.click();
      }
    })
  });

  // override legacy form 'Read More' collapsible text feature for mobile
  // so that the accordion displays on page load
  const accordion = document.querySelector('.accordion')
  const readMoreButton = document.querySelector('#read-more')

  if (accordion && readMoreButton) {
    // 'hide-show-p' class is set in aclu-legacy-form.js and is applied to elements 
    // below the headers and above the form to hide them on mobile
    document.querySelectorAll('.hide-show-p:not(script)').forEach(el => {
      el.style.display = 'block';
    });
    readMoreButton.style.display = 'none'
  }
})