/* eslint
    prettier/prettier: "off",
    func-names: "off",
    no-unused-vars: "off",
    eqeqeq: "off",
    no-useless-concat: "off",
    no-unused-expressions: "off",
    object-shorthand: "off"
*/
(function($) {
  Drupal.behaviors.LegacyFormJS = {
    attach: function (context, settings) {

      $(document).ready(function() {
        var url = window.location.href;
         // check for thank-you added to custom url in webform->form-settings
         var endURL = url.substring(url.lastIndexOf('/') + 1);

        $('.legacy-menu--burger, .chevron').on('click touch', function (event) {
          event.preventDefault;
          // Use class indicator so menu show/hides correctly on browser resize
          if ( $('#header-webform-nav.legacy').hasClass('hamburger-show')) {
            $('#header-webform-nav.legacy').removeClass('hamburger-show');
          } else {
            $('#header-webform-nav.legacy').addClass('hamburger-show');
          }

        });

        $('.hero-item, .hero-image').show();
        $('.page-title').appendTo('.hero-item, .hero-image');

        // For legacy forms if a webform exists float it to the right
        if ($(".node .content .webform-client-form input[name='submitted[two_column]']").length) {
          $("div#content.default-form-wrapper.legacy-wrapper").addClass("two-column");

          // Adding 'Read More' to responsive design
          if (($('.field-name-body', context).height() > '600') && ($(window).width() < '479')) {
            $('.field-name-body > .field-items > .field-item > :nth-child(n+3)').addClass('hide-show-p');
            $('.hide-show-p:last').after('<a href="#readmore"><div id="read-more"><span class="read-more-plus">+</span>' + ' READ MORE' + '</div></a>');
            $('#read-more', context).click(function() {
              $('.field-name-body > .field-items > .field-item > :nth-child(n+3)').removeClass('hide-show-p');
              $('#read-more').remove();
            });
          }
          $('.webform-client-form select').each( function () {
            $(this).parents("div").find("label[for=" + this.id + "]").addClass('select-label');
           });
          $('.webform-client-form textarea').each( function () {
            $(this).parents("div").find("label[for=" + this.id + "]").addClass('textarea-label');
          }).focus( function () {
            $(this).parents("div").find("label[for=" + this.id + "]").hide().text();
          }).blur( function () {
           if ($(this).val() == '')
             $(this).parents("div").find("label[for=" + this.id + "]").show().text();
          });

          // The email field is not a textfield type input make sure it's targeted as well
          var textFieldsWithLabel = $('.webform-client-form .webform-component-textfield > input, .webform-client-form .webform-component-email > input').filter( function () {
            if (this.id == "edit-submitted-amount-for-illustration-minimum-gift-of-10000-in-cash-or-stock") {
              // the label on the gift annuities page shouldn't disappear/reappear
              return false;
            }
            return true;
          });
          textFieldsWithLabel.blur( function () {
            if ($(this).val() == '') {
              $(this).parents("div").find("label[for=" + this.id + "]").show().text();
              $(this).parents("div").find("label[for=" + this.id + "]").removeClass('hide');
            }
          }).focus( function () {
            $(this).parents("div").find("label[for=" + this.id + "]").hide().text();
          });

          // On autofill, remove the labels if appropriate
          jQuery(document).on('secure_prepop_autofilled', function() {
            textFieldsWithLabel.trigger('focus').trigger('blur');
          });

        // The email field is not a textfield type input make sure it's targeted as well
         $('.webform-client-form .webform-component-textfield > input, .webform-client-form .webform-component-email > input').each( function () {
           if (this.id == "edit-submitted-amount-for-illustration-minimum-gift-of-10000-in-cash-or-stock") {
              // the label on the gift annuities page shouldn't disappear/reappear
              return false;
            }
            if ($(this).val() !== '') {
              $(this).parents("div").find("label[for=" + this.id + "]").addClass('hide');
           }
         });
         $('.hide').hide();

          /* the datepicker div is added to the body but we need to be able to target it it hide it */
          $("#ui-datepicker-div").appendTo(".two-column.legacy-wrapper");
          $('<span class="box"><span class="tick"></span></span>').insertAfter('input.form-checkbox');

          if ($('#edit-submitted-other-comments').val() != "") {
            $('label[for=edit-submitted-other-comments]').hide();
          }
        }

        // prevent user from entering non-numerical characters
        function preventNonNumericalInput(e) {
          e = e || window.event;
          var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
          var charStr = String.fromCharCode(charCode);
        
          if (charStr.match(/[^\d.]/g))
            e.preventDefault();
        }

        const amountInputElement = document.getElementById('edit-submitted-amount-for-illustration-minimum-gift-of-10000-in-cash-or-stock')

        function checkMinimumAmount() {
          if (amountInputElement.value < 10000 && amountInputElement.value != '') {
            amountInputElement.setCustomValidity('Please enter a minimum gift of $10,000');
            amountInputElement.reportValidity()
            amountInputElement.addEventListener('input', checkMinimumAmount)
          } else {
            amountInputElement.setCustomValidity('');
          }
        }

        if (!document.querySelector('.webform-confirmation')) {
          amountInputElement.addEventListener('keypress', preventNonNumericalInput)
          amountInputElement.addEventListener('blur', checkMinimumAmount)
        

          amountInputElement.setAttribute('inputmode', 'decimal')

          // add "$" in the "Amount for illustration" field
          const amountInputContainer = document.createElement('div');
          amountInputContainer.setAttribute('id', 'amount-for-illustration-input-container')
          const dollarSign = document.createElement('span');
          dollarSign.setAttribute('id', 'dollar-sign')
          dollarSign.innerHTML = '$'
          amountInputContainer.appendChild(dollarSign)

          const amountContainer = document.getElementById('webform-component-amount-for-illustration-minimum-gift-of-10000-in-cash-or-stock')
          amountContainer.insertAdjacentElement('beforeend', amountInputContainer)
          amountInputContainer.appendChild(amountInputElement)
          amountInputContainer.insertAdjacentElement('afterend', document.querySelector('.description'))
        }
      });
    }
  }
})(jQuery);
